<!-- 资金账单 -->
<template>
	<div :class="themeClass" class="capitalBill">
		<div class="">
			<div class="flex-row-align-center">
				<div style="width: 100%; height: 32px; margin-top: 30px;" class="font-weight700  billtext flex-row font-size18"  >
					资金账单
				</div>
			</div>
			
			<div class="flex-row-align-center margin-t-13 margin-b-10">
				<div class="walletType font-color-666  margin-r-10">
					<span>钱包类型:</span>
					<el-select class="selectBox" v-model="value" placeholder="请选择" @change="typeChange">
						<el-option v-for="(item,index) in walletModeData" :key="item.dropdown" :label="item.dropdown" :value="item.dropdown">
						</el-option>
					</el-select>
				</div>
				<!--  <el-date-picker v-model="value1" type="daterange" range-separator="至" :start-placeholder="defaultTime" :end-placeholder="defaultTime"
					 class="font-size13 margin-l-10 chooseDate" value-format="yyyy-MM-dd" :clearable="false">
					</el-date-picker> -->
			
				<div class="inputBor">
          <!-- 新时间组件 -->
          <Timequery @change="getTimeall"/>
          <!-- 新时间组件 -->
					<!-- <el-date-picker v-model="value1[0]" type="date" placeholder="请选择开始日期">
					</el-date-picker>
					<span>至</span>
					<el-date-picker v-model="value1[1]" type="date" placeholder="请选择结束日期">
					</el-date-picker> -->
				</div>
				<div class="topBtn  bg-color-theme text-center font-color-fff queryBtn margin-l-10" style="cursor: pointer;" @click="query">
					查询
				</div>
				<div :loading="downloadLoading" class="topBtn  bg-color-theme text-center font-color-fff excelBtn pointer" style="cursor: pointer;"
				 @click="ExportEXcel">
					<!--  @click="ExportEXcel" -->
					Excel导出
				</div>
			</div>
		</div>
		
		<div class="tab margin-t-13 flex-row margin-b-10">
			<div class="flex-row">
				<div class="tabItem text-center" :class="tabSelect == 0 ? 'bg-fff color-theme border-lr-F2F2F2' : ''" @click="tabClick(0)">
					统计报表
				</div>
				<div class="tabItem text-center" :class="tabSelect == 1 ? 'bg-fff color-theme border-lr-F2F2F2' : ''" @click="tabClick(1)">
					账单列表
				</div>
			</div>
			<div class="tab-right"></div>
		</div>
		<div v-if="billWalletSummary.length==0&&moneyDashSummary.length==0&&accountPaySummary.length==0" class="flex-row-center-center">
		<svg-icon icon-class="NoDataAvailable" />
		</div>
		<div v-loading="loading" v-if="tabSelect == 0" class="tab-content1 flex-row-start-wrap margin-t-13">
			<div v-if="typeSelect=='现金钱包' || getMoneyShow" class="itemBill" @click="cardClick('现金钱包')">
				<div class="itemTop flex-row-space-between-center" style="background: linear-gradient(45deg, rgba(9, 78, 206, 1) 0%, rgba(9, 78, 206, 1) 0%, rgba(9, 140, 238, 1) 100%, rgba(9, 140, 238, 1) 100%);">
					<div style="font-size: 15px; font-weight:700;color:#fff;">钱包账单</div>
					<i class="iconfont icon-zhangdanguanli" style="font-size: 27px; color:rgba(255,255,255,.3)"></i>
				</div>
				<div class="itemBody">
					<div v-if="moneyDashSummary.fIsopen">
						<div class="flex-row-space-between">
							<div style="font-size: 13px;">收入</div>
							<div style="font-size: 15px;font-weight:700;">{{ moneyDashSummary.fAddData | abs }}</div>
						</div>
						<div class="flex-row-space-between">
							<div style="font-size: 13px;">支出</div>
							<div style="font-size: 15px;font-weight:700;">{{ moneyDashSummary.fMinusData | abs }}</div>
						</div>
					</div>
					<div  v-else  style="width: 100%; height:80px; line-height:80px;text-align:center;">
						您尚未开通现金钱包
					</div>
				</div>
			</div>
			<div v-if="typeSelect=='账服通钱包' || getAccountShow" class="itemBill" @click="cardClick('账服通钱包')">
				<div class="itemTop flex-row-space-between-center" style="background: linear-gradient(45deg, rgba(0, 158, 200, 1) 0%, rgba(0, 158, 200, 1) 0%, rgba(0, 206, 230, 1) 100%, rgba(0, 206, 230, 1) 100%);">
					<div style="font-size: 15px; font-weight:700;color:#fff;">账服通账单</div>
					<i class="iconfont icon-zhangdanguanli" style="font-size: 27px; color:rgba(255,255,255,.3)"></i>
				</div>
				<div class="itemBody">
					<div v-if="accountPaySummary.fIsopen">
						<div class="flex-row-space-between">
							<div style="font-size: 13px;">收入</div>
							<div style="font-size: 15px;font-weight:700;">{{ accountPaySummary.fAddMoney | abs }}</div>
						</div>
						<div class="flex-row-space-between">
							<div style="font-size: 13px;">支出</div>
							<div style="font-size: 15px;font-weight:700;">{{ accountPaySummary.fMinusMoney | abs }}</div>
						</div>
					</div>
					<div v-else  style="width: 100%; height:80px; line-height:80px;text-align:center;">
						您尚未开通账服通
					</div>
				</div>
			</div>
			<div v-if="typeSelect=='票据钱包' || getBillShow" class="itemBill" @click="cardClick('票据钱包')">
				<div class="itemTop flex-row-space-between-center" style="background: linear-gradient(90deg, rgba(0, 153, 102, 1) 0%, rgba(0, 153, 102, 1) 0%, rgba(0, 191, 130, 1) 100%, rgba(0, 191, 130, 1) 100%);">
					<div style="font-size: 15px; font-weight:700;color:#fff;">票据账单</div>
					<i class="iconfont icon-zhangdanguanli" style="font-size: 27px; color:rgba(255,255,255,.3)"></i>
				</div>
				<div class="itemBody">
					<div v-if="billWalletSummary.fIsopen">
						<div class="flex-row-space-between">
							<div style="font-size: 13px;">收入</div>
							<div style="font-size: 15px;font-weight:700;">{{ billWalletSummary.fSumAddData | abs }}</div>
						</div>
						<div class="flex-row-space-between">
							<div style="font-size: 13px;">支出</div>
							<div style="font-size: 15px;font-weight:700;">{{ billWalletSummary.fSumMinusData | abs }}</div>
						</div>
					</div>
					<div v-else style="width: 100%; height:80px; line-height:80px;text-align:center;">
						您尚未开通票据账单
					</div>
				</div>
			</div>
			<div v-if="typeSelect=='交易通' || getJYTShow" class="itemBill" @click="cardClick('交易通')">
				<div class="itemTop flex-row-space-between-center" style="background: linear-gradient(90deg, rgba(102, 153, 204, 1) 0%, rgba(102, 153, 204, 1) 0%, rgba(136, 175, 215, 1) 100%, rgba(136, 175, 215, 1) 100%);">
					<div style="font-size: 15px; font-weight:700;color:#fff;">交易通账单</div>
					<i class="iconfont icon-zhangdanguanli" style="font-size: 27px; color:rgba(255,255,255,.3)"></i>
				</div>
				<div class="itemBody">
					<div v-if="JYTSummary.fIsopen">
						<div class="flex-row-space-between">
							<div style="font-size: 13px;">收入</div>
							<div style="font-size: 15px;font-weight:700;">{{ JYTSummary.fAddData | abs }}</div>
						</div>
						<div class="flex-row-space-between">
							<div style="font-size: 13px;">支出</div>
							<div style="font-size: 15px;font-weight:700;">{{ JYTSummary.fMinusData | abs }}</div>
						</div>
					</div>
					<div  v-else  style="width: 100%; height:80px; line-height:80px;text-align:center;">
						您尚未开通交易通
					</div>
				</div>
			</div>
			<!-- <div v-if="typeSelect=='授信额度' || getCreditShow" class="itemBill" @click="cardClick('授信额度')">
				<div class="itemTop flex-row-space-between-center" style="background: linear-gradient(158.398631431418deg, rgba(100, 102, 255, 1) 0%, rgba(100, 102, 255, 1) 0%, rgba(153, 153, 255, 1) 100%, rgba(153, 153, 255, 1) 100%);">
					<div style="font-size: 15px; font-weight:700;color:#fff;">授信账单</div>
					<i class="iconfont icon-zhangdanguanli" style="font-size: 27px; color:rgba(255,255,255,.3)"></i>
				</div>
				<div class="itemBody">
					<div class="flex-row-space-between">
						<div style="font-size: 13px;">收入</div>
						<div style="font-size: 15px;font-weight:700;">{{ billWalletSummary.fSumAddData | abs }}</div>
					</div>
					<div class="flex-row-space-between">
						<div style="font-size: 13px;">支出</div>
						<div style="font-size: 15px;font-weight:700;">{{ billWalletSummary.fSumMinusData | abs }}</div>
					</div>
				</div>
			</div> -->
		</div>
		<div v-loading="tableLoading" v-if="tabSelect == 1" class="tab-content2 margin-t-13">
			<!-- <div class="walletType font-color-666 font-size13 margin-r-10">
				<span>钱包类型：</span>
				<el-select class="selectBox" v-model="value" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<span>票据钱包</span>
			</div> -->
			<div class="walletType font-color-666 font-size13 margin-r-10" >
				<div>
					<span class="margin-l-20" style="display: inline-block;margin-right: 20px;border: 1px #eee solid;padding: 5px 20px;cursor: pointer;color: #0173FE;" @click="backlist"><<返回</span>
					<span style="font-weight: bold;">钱包类型：</span>
					<span>{{moneyBoxName}}</span>
				</div>
			</div>
			<el-table ref="mul"  :data="tableData" stripe border :row-style="{ height: '36px' }" class="margin-t-13" :header-cell-style="{ 'text-align': 'center', background: '#e9f3ff' ,'font-weight':'500',color: '#000','font-size':'13px'}">
				<el-table-column v-if='value == "账服通钱包"' key='0'  prop="fShopName" label="收款人" width="120" align="left" show-overflow-tooltip>
				</el-table-column>
				<el-table-column v-if='value != "账服通钱包"' key='1' prop="fShopName" label="店铺" width="175" align="left" show-overflow-tooltip>
				</el-table-column>
				<el-table-column v-if="value != '交易通'" prop="fBusinessTime" key='2' label="发生时间" width="140" align="center" show-overflow-tooltip></el-table-column>
				<!-- <el-table-column prop="fChangeCapital" label="资金" width="140" align="right"></el-table-column> -->
				<el-table-column v-if="value != '交易通'" prop="fBeginBalance" key='3' label="期初余额(元)" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column v-if="value != '账服通钱包'" key='4' prop="fAddData" label="充值金额(元)" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column v-if="value != '账服通钱包'" key='5' prop="fMinusData" label="支出金额(元)" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column v-if="value == '账服通钱包'" key='6' prop="fAddMoney" label="充值金额(元)" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column v-if="value == '账服通钱包'" key='7' prop="fMinusMoney" label="支出金额(元)" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column v-if="value == '账服通钱包'" key='8' prop="fLockingMoney" label="锁定金额(元)" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column v-if="value == '账服通钱包'" key='9' prop="fUnLockingMoney" label="解锁金额(元)" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column  v-if='value == "账服通钱包"' key='10' prop="fReceiptsMoney" label="收入金额(元)" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column v-if='value == "账服通钱包"'  key='11' prop="fPayMoney" label="支出金额(元)" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column v-if="value != '交易通'" prop="fEndBalance" key='12' label="期末余额(元)" width="100" align="right" show-overflow-tooltip></el-table-column>
				<el-table-column v-if='value == "账服通钱包"' key='13'  prop="fPlatTxSn" label="交易流水号" width="110" align="left" show-overflow-tooltip></el-table-column>
				<el-table-column v-if='value == "账服通钱包"' prop="fRemark" key='14' label="备注" width="120" align="left" show-overflow-tooltip></el-table-column>
				<el-table-column v-if='value != "账服通钱包"' prop="fRemark" key='15' label="备注" width="auto" align="left" show-overflow-tooltip></el-table-column>
			</el-table>
		</div>
		<div v-if="tabSelect != 0" class="flex-row-center-flex-end margin-t-20 margin-b-30">
			<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
			 :pagesize="pagesize" :total="total"></pagination>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
  import Timequery from "@/components/Timequery.vue"
	import pagination from '@/components/pagination/pagination.vue';
	export default {
		components: {
			pagination,
      Timequery
		},
		props: {},
		data() {
			return {
				tableLoading:false,
				getMoneyShow:false,//现金钱包显示
				getAccountShow:false,//帐服通显示
				getBillShow:false,//票据钱包
				getJYTShow:false,//交易通钱包
				getCreditShow:false,//授信额度
				tablebles: [{
						label: "店铺",
						width: "100",
						prop: "fShopName",
						align: "left",
						fixed: true
					},
					{
						label: "单据号码",
						width: "100",
						prop: "fSourceBillNumber",
						align: "left",
						fixed: true
					},
					{
						label: "发生时间",
						width: "100",
						prop: "fBusinessTime",
						align: "center",
						fixed: true
					},
					// {
					// 	label: "资金",
					// 	width: "100",
					// 	prop: "fChangeCapital",
					// 	align: "center",
					// 	fixed: true
					// },
					{
						label: "期初余额(元)",
						width: "100",
						prop: "fBeginBalance",
						align: "right",
						fixed: true
					},
					{
						label: "充值金额(元)",
						width: "100",
						prop: "fAddData",
						align: "right",
						fixed: true
					},
					{
						label: "支出金额(元)",
						width: "100",
						prop: "fMinusData",
						align: "right",
						fixed: true
					},
					{
						label: "收入金额(元)",
						width: "100",
						prop: "fReceiptsMoney",
						align: "right",
						fixed: true
					},
					{
						label: "支出金额(元)",
						width: "100",
						prop: "fPayMoney",
						align: "right",
						fixed: true
					},
					{
						label: "期末余额(元)",
						width: "100",
						prop: "fEndBalance",
						align: "right",
						fixed: true
					},
					{
						label: "备注",
						width: "200",
						prop: "fRemark",
						align: "left",
						fixed: true
					}
				],
				tablebles1: [{
						label: "收款人",
						width: "100",
						prop: "fShopName",
						align: "left",
						fixed: true
					},
					{
						label: "单据号码",
						width: "100",
						prop: "fSourceBillNumber",
						align: "left",
						fixed: true
					},
					{
						label: "发生时间",
						width: "100",
						prop: "fBusinessTime",
						align: "center",
						fixed: true
					},
					// {
					// 	label: "资金",
					// 	width: "100",
					// 	prop: "fChangeCapital",
					// 	align: "center",
					// 	fixed: true
					// },
					{
						label: "期初余额(元)",
						width: "100",
						prop: "fBeginBalance",
						align: "right",
						fixed: true
					},
					{
						label: "充值金额(元)",
						width: "100",
						prop: "fAddMoney",
						align: "right",
						fixed: true
					},
					{
						label: "支出金额(元)",
						width: "100",
						prop: "fMinusMoney",
						align: "right",
						fixed: true
					},
					{
						label: "锁定金额(元)",
						width: "100",
						prop: "fLockingMoney",
						align: "right",
						fixed: true
					},
					{
						label: "解锁金额(元)",
						width: "100",
						prop: "fUnLockingMoney",
						align: "right",
						fixed: true
					},
					{
						label: "收入金额(元)",
						width: "100",
						prop: "fReceiptsMoney",
						align: "right",
						fixed: true
					},
					{
						label: "支出金额(元)",
						width: "100",
						prop: "fPayMoney",
						align: "right",
						fixed: true
					},
					{
						label: "期末余额(元)",
						width: "100",
						prop: "fEndBalance",
						align: "right",
						fixed: true
					},
					{
						label: "交易流水号",
						width: "100",
						prop: "fPlatTxSn",
						align: "right",
						fixed: true
					},
					{
						label: "备注",
						width: "200",
						prop: "fRemark",
						align: "left",
						fixed: true
					}
				],
				tablebles2: [
					{
						label: "店铺",
						width: "100",
						prop: "fShopName",
						align: "left",
						fixed: true
					},
					{
						label: "充值金额(元)",
						width: "100",
						prop: "fAddData",
						align: "right",
						fixed: true
					},
					{
						label: "支出金额(元)",
						width: "100",
						prop: "fMinusData",
						align: "right",
						fixed: true
					},
					{
						label: "备注",
						width: "200",
						prop: "fRemark",
						align: "left",
						fixed: true
					}
				],
				loading: false,
				defaultTime: '', //时间默认值
				value: "全部钱包", //钱包类型下拉框v-model绑定的值
				typeSelect: 0, //钱包类型value
				value1: [new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 90)), new Date()], //时间
				fBeginTime: "",
				fEndTime: "",
				tabSelect: 0, //tab选中项
				billWalletSummary: {
					fSumAddData: 0,
					fSumMinusData: 0,
					fIsopen: 0
				}, //票据钱包汇总
				moneyDashSummary: {
					fAddData: 0,
					fMinusData: 0,
					fIsopen: 0
				}, //现金钱包汇总
				accountPaySummary: {
					fAddMoney: 0,
					fMinusMoney: 0,
					fIsopen: 0
				}, //账服通钱包汇总
				JYTSummary: {
					fAddData: 0,
					fMinusData: 0,
					fIsopen: 0
				},//交易通汇总
				tableData: [], //订单列表表格数据
				requestUrl: '', //订单列表接口url
				downloadLoading: false,
				exUrl: '',
				tableDatamodel: [],
				total: 0, //总条数
				pagesize: 15, //每页显示条目个数
				currentPage: 1, //当前页
				moneyBoxName:"",
				walletModeData:[],
				
			};
		},
		created() {
			// this.fBeginTime = this.getDate(this.value1[0]);
			// this.fEndTime = this.getDate(this.value1[1]);
			// this.getwalletType();
			// this.getdayValue();
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		filters: {
			//   取绝对值
			abs(val) {
				return Math.abs(val);
			},
		},
		methods: {
      getTimeall (val) {
        console.log(val)
        this.fBeginTime = val[0]
			  this.fEndTime = val[1]
        this.getwalletType();
			  this.getdayValue();
      },
			// 
			getwalletType(){
				this.ApiRequestPost('api/mall/ebbalance/customer-cash-detail/get-drop-down').then(
				result=>{
					console.log(result)
					this.walletModeData=result.obj;
					this.walletModeData.unshift({
						dropdown: '全部钱包'
					});
					this.value=this.walletModeData[0].dropdown
					this.getSummary();
				})
			},
			//点击钱包类型
			typeChange(e){
				console.log(e)
				this.value = e
				
				if(this.value == '全部钱包'){
					this.moneyBoxName = '全部钱包'
				}else if(this.value == '现金钱包'){
					this.moneyBoxName = '现金钱包'
				}else if(this.value == '账服通钱包'){
					this.moneyBoxName = '账服通钱包'
				}else if(this.value == '票据钱包'){
					this.moneyBoxName = '票据钱包'
				}else if(this.value == '交易通'){
					this.moneyBoxName = '交易通'
				}else if(this.value == '授信额度'){
					this.moneyBoxName = '授信额度'
				}
				this.query()
			},
			getdayValue() {
				let a = new Date()
				this.defaultTime = this.getDate(a)
			},
			// tab切换
			tabClick(val) {
				this.tabSelect = val
				if (this.tabSelect == 0) {
					// this.getSummary()
					this.query()
				} else {
					if(this.value == '全部钱包'){
						this.moneyBoxName = '全部钱包'
					}else if(this.value =='现金钱包'){
						this.moneyBoxName = '现金钱包'
					}else if(this.value == '账服通钱包'){
						this.moneyBoxName = '账服通钱包'
					}else if(this.value =='票据钱包'){
						this.moneyBoxName = '票据钱包'
					}else if(this.value == '交易通'){
						this.moneyBoxName = '交易通'
					}else if(this.value == '授信额度'){
						this.moneyBoxName = '授信额度'
					}
					this.getDetails()
				}
			},
			// 点击查询
			query() {
				this.getMoneyShow = false//现金钱包显示
				this.getAccountShow = false//帐服通显示
				this.getBillShow = false//票据钱包
				this.getJYTShow = false,//交易通钱包
				this.getCreditShow = false,//授信额度
				// debugger
				this.currentPage = 1;
				if (this.value1.length > 0) {
					if (this.getDate(this.value1[1]) < this.getDate(this.value1[0])) {
						return this.$message('结束时间不能早于开始时间');
					} else {
						this.fBeginTime = this.getDate(this.value1[0]);
						this.fEndTime = this.getDate(this.value1[1]);
					}

				}
				this.typeSelect = this.value
				if (this.tabSelect == 0) {
					this.getSummary()
				} else {
					this.getDetails();
				}
			},
			// 返回列表
			backlist(){
				this.tabSelect = 0
				// this.value = 0
				this.typeSelect = this.value
				// this.getSummary()
				this.query()
			},
			//   获取汇总数据
			getSummary() {
				console.log("查询时获取ID",this.value)
				this.getMoneyShow = false//现金钱包显示
				this.getAccountShow = false//帐服通显示
				this.getBillShow = false//票据钱包
				this.getJYTShow = false//交易通钱包
				this.getCreditShow = false//授信额度
				switch (this.value) {
					case '全部钱包':
					console.log(this.walletModeData,"this.walletModeData====")
						// this.walletModeData.indexOf()
						this.walletModeData.forEach((item,index)=>{
							if(item.dropdown == "现金钱包"){
								this.getMoneySummary()
							}else if(item.dropdown == "账服通钱包"){
								this.getAccountSummary()
							}else if(item.dropdown == "票据钱包"){
								this.getBillSummary()
							}else if(item.dropdown == "交易通"){
								this.getJYTBill()
							}else if(item.dropdown == "授信额度"){
								// this.getBillSummary()
							}
						})
						break;
					case '现金钱包':
						this.getMoneySummary()
						break;
					case '账服通钱包':
						this.getAccountSummary()
						break;
					case '票据钱包':
						this.getBillSummary()
						break;
					case '交易通':
						this.getJYTBill()
						break;
					case '授信额度':
						// this.getBillSummary()
						break;
					default:
						break;
				}
			},
			// 现金钱包汇总
			getMoneySummary() {

				// if (this.value1.length > 0) {
				//   this.fBeginTime = this.getDate(this.value1[0]);
				//   this.fEndTime = this.getDate(this.value1[1]);
				// }
				this.loading = true
				this.ApiRequestPost(
					"api/mall/ebbalance/customer-cash-detail/get-cashdetails-summery", {
						beginTime: this.fBeginTime,
						endTime: this.fEndTime,
						// maxResultCount: 10,
						// filter: "",
						// sorting: "",
						// skipCount: 0,
					}
				).then(
					(result) => {
						console.log('现金', result);
						this.loading = false
						if (result.obj.length != 0) {
							this.moneyDashSummary = result.obj[0];
							this.getMoneyShow = true
							console.log()
						}else{
							this.getMoneyShow = false
						}

					},
					(rej) => {
						this.loading = false
					}
				);
			},
			// 账服通钱包汇总
			getAccountSummary() {


				this.loading = true
				this.ApiRequestPost(
					"api/mall/ebbalance/customer-acs-detail/get-ACSdetail-summery", {
						beginTime: this.fBeginTime,
						endTime: this.fEndTime,
						// maxResultCount: 10,
						// filter: "",
						// sorting: "",
						// skipCount: 0,
					}
				).then(
					(result) => {
						this.loading = false
						if (result.obj.length != 0) {
							this.accountPaySummary = result.obj[0];
							this.getAccountShow = true
						}else{
							this.getAccountShow = true
						}

					},
					(rej) => {
						this.loading = false
					}
				);
			},
			// 票据钱包汇总
			getBillSummary() {
				this.loading = true
				this.ApiRequestPost(
					"api/mall/ebbalance/customer-acceptance-detail/get-summary", {
						beginTime: this.fBeginTime,
						endTime: this.fEndTime,
						// maxResultCount: 10,
						// filter: "",
						// sorting: "",
						// skipCount: 0,
					}
				).then(
					(result) => {
						console.log('票据', result);
						this.loading = false
						if (result.obj.length != 0) {
							this.billWalletSummary = result.obj[0];
							this.getBillShow = true
						}else{
							this.getBillShow = false
						}

					},
					(rej) => {
						this.loading = false
					}
				);
			},
			// 交易通汇总
			getJYTBill() {
				this.loading = true
				this.ApiRequestPost(
					"api/mall/payment/jyt-deal-records/get-jyt-sumstatistics", {
						beginTime: this.fBeginTime,
						endTime: this.fEndTime,
						// maxResultCount: 10,
						// filter: "",
						// sorting: "",
						// skipCount: 0,
					}
				).then(
					(result) => {
						console.log('交易通', result);
						this.loading = false
						if (result.obj.length != 0) {
							this.JYTSummary = result.obj[0];
							this.getJYTShow = true
						}else{
							this.getJYTShow = false
						}
				
					},
					(rej) => {
						this.loading = false
					}
				);
			},
			// 点击汇总
			cardClick(val) {
				this.value = val
				console.log("钱包类型",this.value)
				switch (this.value) {
					case "现金钱包":
						this.moneyBoxName = "现金钱包"
						break;
					case "账服通钱包":
						this.moneyBoxName = "账服通钱包"
						break;
					case "票据钱包":
						this.moneyBoxName = "票据钱包"
						break;
					case "交易通":
						this.moneyBoxName = "交易通"
						break;
					case "授信额度":
						this.moneyBoxName = "授信额度"
						break;
					default:
						break;
				}
				this.getDetails()
				this.tabClick(1)
			},
			//千分位的设置
			NumFormat(value) {
				if (!value) return '0.00';
				value = Number(value)
					.toFixed(2)
					.toString();
				var intPart = parseInt(Number(value)); //获取整数部分
				var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断
				var floatPart = '.00'; //预定义小数部分
				var value2Array = value.split('.');
				//=2表示数据有小数位
				if (value2Array.length == 2) {
					floatPart = value2Array[1].toString(); //拿到小数部分
					if (floatPart.length == 1) {
						//补0,实际上用不着
						return intPartFormat + '.' + floatPart + '0';
					} else {
						return intPartFormat + '.' + floatPart;
					}
				} else {
					return intPartFormat + floatPart;
				}
			},
			
			//   获取明细数据
			getDetails() {


				//  if (this.value1.length > 1) {
				//  	if (this.getDate(this.value1[1]) < this.getDate(this.value1[0])) {
				//  		return this.$message('结束时间不能早于开始时间');
				//  	}else{
				// 	if (this.value1.length > 0) {
				// 	  this.fBeginTime = this.getDate(this.value1[0]);
				// 	  this.fEndTime = this.getDate(this.value1[1]);
				// 	}
				// }
				//  }
                this.tableLoading=true;
				console.log(this.fBeginTime, this.fEndTime);
				console.log(this.value)
				if (this.value == '全部钱包') {
					console.log(0);
					this.requestUrl = "api/mall/ebbalance/customer-cash-detail/get-capitaldetail-summary"
				}
				if (this.value == '现金钱包') {
					console.log(1);
					this.requestUrl = "api/mall/ebbalance/customer-cash-detail/get-cashdetails-by-customerid"
				}
				if (this.value == "账服通钱包") {
					console.log(2);
					this.requestUrl = "api/mall/ebbalance/customer-acs-detail/get-ACSdetail-by-customerid"
				}
				if (this.value ==  "票据钱包") {
					console.log(3);
					this.requestUrl = "api/mall/ebbalance/customer-acceptance-detail/get-details-by-cusetomerid"
				}
				if (this.value ==  "交易通") {
					console.log(4);
					this.requestUrl = "api/mall/payment/jyt-deal-records/get-list-detail"
				}
				this.ApiRequestPost(
					this.requestUrl, {
						beginTime: this.fBeginTime,
						endTime: this.fEndTime,
						maxResultCount: this.pagesize,
						filter: '',
						sorting: '',
						skipCount: (this.currentPage - 1) * this.pagesize
					}
				).then(
					(result) => {
						console.log(result);
						this.$nextTick(()=>{
							this.$refs.mul.doLayout();
						})
						result.obj.items.forEach((item) => {
							if(item.fBusinessTime !=null){
								item.fBusinessTime = this.getTime(item.fBusinessTime)
							}
							if (item.fChangeCapital > 0) {
								item.fChangeCapital = "+".concat(item.fChangeCapital);
							}
							if(item.fBeginBalance){
								item.fBeginBalance = this.NumFormat(item.fBeginBalance)
							}
							if(item.fLockingMoney){
								item.fLockingMoney = this.NumFormat(item.fLockingMoney)
							}
							if(item.fUnLockingMoney){
								item.fUnLockingMoney = this.NumFormat(item.fUnLockingMoney)
							}
							if(item.fReceiptsMoney){
								item.fReceiptsMoney = this.NumFormat(item.fReceiptsMoney)
							}
							if(item.fPayMoney){
								item.fPayMoney = this.NumFormat(item.fPayMoney)
							}
							if(item.fAddData){
								item.fAddData = this.NumFormat(item.fAddData)
							}
							if(item.fAddMoney){
								item.fAddMoney = this.NumFormat(item.fAddMoney)
							}
							if(item.fMinusMoney){
								item.fMinusMoney = this.NumFormat(item.fMinusMoney)
							}
							if(item.fMinusData){
								item.fMinusData = this.NumFormat(item.fMinusData)
							}
							if(item.fEndBalance){
								item.fEndBalance = this.NumFormat(item.fEndBalance)
							}
							
						});
						this.tableData = result.obj.items;
						this.total = result.obj.totalCount;
						this.tableLoading=false;
					},
					(rej) => {
						this.tableLoading=false;
					}
				);
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log('下一页')
				console.log(val)
				this.currentPage = val
				this.getDetails();
			},
			// EXcel导出
			ExportEXcel() {
				if (this.getDate(this.fEndTime) < this.getDate(this.fBeginTime)) {
					return this.$message('结束时间不能早于开始时间');
				}

				if (this.tableData.length == 0) {
					return this.$message('导出数据不能为空！');
				}
				if (this.value == '全部钱包') {

					this.exUrl = "api/mall/ebbalance/customer-cash-detail/down-detail-summary"
				}
				if (this.value == '现金钱包') {

					this.exUrl = "api/mall/ebbalance/customer-cash-detail/down-detail-by-customerid"
				}
				if (this.value == "账服通钱包") {

					this.exUrl = "api/mall/ebbalance/customer-acs-detail/down-ACSdetail-by-customerid"
				}
				if (this.value == "票据钱包") {

					this.exUrl = "api/mall/ebbalance/customer-acceptance-detail/down-detail-by-customerid"
				}
				if (this.value == "交易通") {
				
					this.exUrl = "api/mall/payment/jyt-deal-records/get-list-detail-down"
				}
				this.downloadLoading = true;
				this.tableDatamodel = [];
				console.log(this.value,'================================')
				if(this.value == "账服通钱包"){
					for (var i = 0; i < this.tablebles1.length; i++) {
						this.tableDatamodel.push({
							Key: this.tablebles1[i].prop,
							Value: this.tablebles1[i].label
						})
					}
					console.log(this.tableDatamodel,'if--------------------')
				}else if (this.value == "交易通") {
					for (var i = 0; i < this.tablebles2.length; i++) {
						this.tableDatamodel.push({
							Key: this.tablebles2[i].prop,
							Value: this.tablebles2[i].label
						})
					}
					console.log(this.tableDatamodel,'else if--------------------')
				}else{
					for (var i = 0; i < this.tablebles.length; i++) {
						this.tableDatamodel.push({
							Key: this.tablebles[i].prop,
							Value: this.tablebles[i].label
						})
					}
					console.log(this.tableDatamodel,'else+++++++++++++++++++++++++++++++++++++++++++++++++')
				}
				console.log('账服通导出',this.tableDatamodel)
				this.ApiRequestPostNOMess(
					this.exUrl, {
						model: this.tableDatamodel,
						beginTime: this.fBeginTime,
						endTime: this.fEndTime
					}
				).then(
					(result) => {

						window.open(result.obj)
						this.downloadLoading = false;
					},
					(rej) => {
						this.downloadLoading = false;
					}
				);
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__icon{
		line-height: 25px !important;
	}
	.inputBor {
		width: 427px;
		display: flex;
		border: 1px solid #f2f2f2;
		border-radius: 2px;

		/deep/.el-input__inner {
			border: 0 !important;
		}

		span {
			line-height: 30px;
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.bg-color-theme {
		@include themify($themes) {
			background: themed("themes_color");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.margin-t-8 {
		margin-top: 8px;
	}

	.capitalBill {
		// width: 100%;
		width: 921px;
	}

	.top {
		width: 100%;
		height: 32px;
	}

	.selectBox {
		width: 121px;
		margin-left: 15px;
	}

	.chooseDate {
		width: 354px;
	}

	.excelBtn {
		margin-left: 6px;
		width: 110px;
		height: 32px;
		line-height: 32px;
	}

	.queryBtn {
		width: 75px;
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-input {
		height: 32px;
		line-height: 32px;
	}

	.selectBox {
		/deep/ .el-input__inner {
			color: #666;
		}
	}

	/deep/ .el-input__inner {
		height: 32px !important;
		line-height: 32px !important;
	}

	/deep/ .el-date-editor .el-range__icon {
		height: 32px;
	}

	/deep/ .el-date-editor .el-range-separator {
		height: 32px;
	}

	/deep/ .el-input__suffix-inner {
		line-height: 50px;
		display: none;
	}

	.el-select__caret .el-input__icon .el-icon-arrow-up {
		line-height: 32px;
	}

	.flex1 {
		flex: 1;
	}

	.tab {
		width: 100%;
		height: 40px;
		background-color: #f8f9fe;
		border: 1px solid #f2f2f2;
		border-bottom: none;
	}

	.tabItem {
		width: 108px;
		height: 40px;
		line-height: 40px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.borderTab {
		border: 1px solid #f2f2f2;
		border-bottom: none;
	}

	.tab-content1 {
		width: 100%;
	}

	.tab-content1-item {
		width: 49%;
		height: 135px;
		border-radius: 8px;
		box-sizing: border-box;
		background-color: #F7FBFF;
		margin-bottom: 10px;
		box-shadow: 0px 0px 8px 1px #e1e1f2;

		&:nth-child(2n) {
			margin-left: 10px;
		}
	}

	.left {
		flex: 1;
		height: 104px;
	}

	.right {
		width: 156px;
		height: 104px;
		margin-right: 5px;
		background: url(../../../assets/u248.png) no-repeat;
		background-size: contain;
		background-position: center;
	}

	tab-content2 {
		width: 100%;
	}

	/deep/ .el-table td {
		padding: 0;
	}

	/deep/ .el-table th {
		height: 36px;
		padding: 0;
	}

	.font-size20 {
		font-weight: 700;
	}
	::v-deep .el-table .cell {
		white-space: nowrap;
		padding-left:5px !important;
		padding-right:5px !important;
		font-size: 12px;
	}
	.billtext {
		line-height: 33px;
	}
	.itemBill {
		width: 296px;
		height: 135px;
		border-radius: 6px;
		border: 1px solid #D7D7D7;
		margin-bottom: 13px;
		margin-left: 9px;
		.itemTop {
			height: 48px;
			padding: 12px 13px;
			box-sizing: border-box;
			border-radius: 6px 6px 0 0;
		}
		.itemBody {
			padding: 0 22px;
			line-height: 40px;
		}
	}
</style>

